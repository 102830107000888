export function getCookieValue(name) {
    return  document.cookie.split(";")
        .map(c => c.trim().split("="))
        .filter(x => x[0] === name)
        .map(x => x[1])[0];
}

export function storeCookieValue(key, value, domain, maxAgeYears = 1) {
    document.cookie = `${key}=${value}; ${domain && (`domain=${domain};`)} max-age=${31536000 * maxAgeYears}`;
}
