export async function getPermitGroup(actorPermitGroupId) {
  const url =
    process.env.REACT_APP_PERMITGROUPS_URL +
    `?actorPermitGroupId=${actorPermitGroupId}`;

  return get(url);
}

export async function getPermit(actorPermitId) {
  const url =
    process.env.REACT_APP_PERMITS_URL + `?actorPermitId=${actorPermitId}`;

  return get(url);
}

export async function signPermitGroup(actorPermitGroupId, isMobile) {

  return post(process.env.REACT_APP_PERMITGROUPS_SIGN_URL, {
    actorPermitGroupId: actorPermitGroupId,
    autoStartUrlFormatForMobileDevice: isMobile
  });
}

export async function signPermit(actorPermitId, isMobile) {

    return post(process.env.REACT_APP_PERMITS_SIGN_URL, {
      actorPermitId: actorPermitId,
      autoStartUrlFormatForMobileDevice: isMobile
    });
}

export function getPermitPdfBlob(actorPermitId) {
  const url = `${process.env.REACT_APP_PERMITS_PDF_URL}?actorPermitId=${actorPermitId}`

  return getBlob(url);
}

export async function getSignatureStatus(id) {
  const uri = `${process.env.REACT_APP_SIGNATURE_COLLECT_URL}/${id}`

  return get(uri);
}

async function get(url) {
  return fetch(url, {
    method: "GET",
    credentials:"include"
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        const errorMessageTranslationKey = response.status === 401 ? "logInAgain" : "";
        return { isSuccessful: false, errorMessageTranslationKey };
      }
    })
    .catch({ isSuccessful: false });
}

async function post(url, requestBodyObject) {
  return fetch(url, {
    method: "POST",
    credentials:"include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBodyObject)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        const errorMessageTranslationKey = response.status === 401 ? "logInAgain" : "";
        return { isSuccessful: false, errorMessageTranslationKey };
      }
    })
    .catch({ isSuccessful: false });
}

async function getBlob(url, options) {
  return fetch(url, {
    method: "GET",
    credentials:"include"
  })
    .then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        const errorMessageTranslationKey = response.status === 401 ? "logInAgain" : "";
        return { isSuccessful: false, errorMessageTranslationKey };
      }
    })
    .catch({ isSuccessful: false });
}