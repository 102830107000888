import { useState, useEffect } from "react";
import { getQueryParam } from "./utils/uriHelper";
import { translations } from "./utils/translations";
import Sign from "./components/Sign";
import "./App.css";

function App() {
  const [actorPermitGroupId, setActorPermitGroupId] = useState("");
  const [actorPermitId, setActorPermitId] = useState("");

  useEffect(() => {
    setActorPermitGroupId(getQueryParam("actorPermitGroupId") ?? "");
    setActorPermitId(getQueryParam("actorPermitId") ?? "");
  }, []);

  return (
    <div className="App">
      <Sign
        actorPermitGroupId={actorPermitGroupId}
        actorPermitId={actorPermitId}
        translations={translations}
      />
    </div>
  );
}

export default App;
