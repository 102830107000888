import { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import os from "platform-detect/os";
import {
  getPermit,
  signPermit,
  getPermitGroup,
  signPermitGroup,
  getPermitPdfBlob,
  getSignatureStatus
} from "../api/permitApi";
import { getCookieValue } from "../utils/cookie";
import { formatDate } from "../utils/dateHelper";
import { translations } from "../utils/translations";
import CopyrightNotice from "./copyrightNotice";

function checkCanSignPermit(actorPermit) {
  return (
    actorPermit.responsibleSignatureId != null &&
    actorPermit.signatureId == null
  );
}

function checkCanSignPermitGroup(actorPermitGroup) {
  return actorPermitGroup.responsibleSignatureId == null;
}

function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(url);
  }, 100);
  return Promise.resolve();
}

let useLocalBankIdApp;
const cookieValue = getCookieValue("useLocalBankIdApp");
useLocalBankIdApp = cookieValue && JSON.parse(cookieValue);

const errorMessageOrDefault = (translationKey) =>
  (translationKey && translations[translationKey]) || translations.genericErrorMessage;

export default function Sign(props) {
  const [permits, setPermits] = useState([]);
  const [signDate, setSignDate] = useState("");
  const [canSign, setCanSign] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [loadingPdfPermitIds, setLoadingPdfPermitIds] = useState([]);
  const isMobile = os.ios || os.android;
  
  function visibilityChange() {
    if(document["hidden"]) {
    } else {
      collect();
    }
  }

  const loadPermitGroup = useCallback(async () => {
    const { isSuccessful, errorMessageTranslationKey, actorPermitGroup } = await getPermitGroup(
      props.actorPermitGroupId
    );
    if (isSuccessful) {
      setPermits(actorPermitGroup.actorPermits);
      setCanSign(checkCanSignPermitGroup(actorPermitGroup));
      setSignDate(formatDate(actorPermitGroup.responsibleSignatureDate));
    } else {
      setError(errorMessageOrDefault(errorMessageTranslationKey));
    }
  }, [props]);

  const loadPermit = useCallback(async () => {
    const { isSuccessful, errorMessageTranslationKey, actorPermit } = await getPermit(props.actorPermitId);
    if (isSuccessful) {
      setPermits([actorPermit]);
      setCanSign(checkCanSignPermit(actorPermit));
      setSignDate(formatDate(actorPermit.signatureDate));
    } else {
      setError(errorMessageOrDefault(errorMessageTranslationKey));
    }
  }, [props]);

  const loadData = useCallback(async () => {
    setIsLoading(true);
      if (props.actorPermitGroupId) {
        await loadPermitGroup();
      } else if (props.actorPermitId) {
        await loadPermit();
      }
    setIsLoading(false);
  }, [loadPermit, loadPermitGroup, props]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  async function collect() {
    const orderRef = window.sessionStorage.getItem("orderRef");
    if(orderRef)
    {
      setUserMessage("Väntar på signering"); // TODO: translation
      setIsLoading(true);
      //alert("getstatus " + orderRef);
      const resp = await getSignatureStatus(orderRef); //, collectRequestCancellation.signal)
      window.sessionStorage.removeItem("orderRef");
      setIsLoading(false);
      // alert(JSON.stringify(resp));
      if(resp.isSuccessful) {
        setUserMessage("");
        setError("");
        loadData();
      } else {
        setUserMessage("");
        setError(resp.userErrorMessage ?? props.translations.genericErrorMessage);
      }
    }
  } 

  async function sign() {
    setIsLoading(true);
    setError("");
    try {
      const { isSuccessful, userErrorMessage, orderRef, autoStartUrl } = props.actorPermitGroupId
        ? await signPermitGroup(props.actorPermitGroupId, isMobile)
        : await signPermit(props.actorPermitId, isMobile);
      if (isSuccessful) {
        window.sessionStorage.setItem("orderRef", orderRef);
        window.addEventListener("visibilitychange", visibilityChange);

        if(autoStartUrl && useLocalBankIdApp) {
          setUserMessage("Försöker starta BankId appen");
          window.location.href = autoStartUrl;
        } else {
          setUserMessage("Starta BankId appen och signera..."); // props.translations.signClickedMessage);
          setTimeout(() => collect(), 3000);
        }
      } else {
        window.sessionStorage.removeItem("orderRef");
        setError(userErrorMessage ?? props.translations.genericErrorMessage);
      }
    } catch {
      window.sessionStorage.removeItem("orderRef");
      setError(props.translations.genericErrorMessage);
    }
  }

  const permitPdfClick = (evt, permit) => {
    evt.preventDefault();
    setLoadingPdfPermitIds([permit.actorPermitId, ...loadingPdfPermitIds]);
    const filename = `${props.translations.actorPermit}_${permit.skillGroupDescription}_${permit.actorName}.pdf`;
    getPermitPdfBlob(permit.actorPermitId)
      .then(blob => downloadBlob(blob, filename))
      .finally(() => setLoadingPdfPermitIds(loadingPdfPermitIds.filter(x => x === permit.actorPermitId)));
  }

  return (
    <div className="permit-container">
      <div className="permit-row">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Namn</th>
              <th scope="col">Företag</th>
              <th scope="col">Kompetensgrupp</th>
              <th scope="col">E-post</th>
              <th scope="col">Körtillstånd</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {permits.map((p) => (
              <tr key={p.actorPermitId}>
                <td>{p.actorName}</td>
                <td>{p.parentActorName}</td>
                <td>{p.skillGroupDescription}</td>
                <td>{p.actorEmail}</td>
                <td>
                  <a href="#" onClick={(evt) => permitPdfClick(evt, p)} >
                    PDF
                  </a>
                  </td>
                <td>
                  { loadingPdfPermitIds.some(x => x === p.actorPermitId) && <Spinner animation="border" variant="secondary" /> }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <p className="permit-row"><span>{userMessage}</span></p>

      <p className="permit-row">{isLoading && "Väntar..."}</p>
      
      <p className="permit-row">{isLoading && <Spinner animation="border" variant="primary" />}</p>

      <p className="permit-row">{signDate && (props.translations.wasSigned + " " + signDate)}</p>

      <p className="permit-row">{error.length > 0 && error}</p>

      <div className="permit-row">
        <Button type="button" onClick={sign} disabled={!canSign || isLoading}>
          Signera
        </Button>
      </div>
      <p className="permit-row"><CopyrightNotice /></p>
    </div>
  );
}
