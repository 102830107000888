
let lastErrorReport = "";

export const globalErrorHandler = (event, source, lineNo, colno, error) => {

    const message = `Error in actor permits\n`
                  + `Url: ${window.location}\n`
                  + `Browser vendor: ${window.navigator.vendor}\n`
                  + `UserAgent: ${window.navigator.userAgent}\n`
                  + `Event: ${event}\n`
                  + `Source: ${source}\n`
                  + `Line: ${lineNo}\n`
                  + `Col: ${colno}\n`
                  + `Exception: ${error}\n`
                  + `Stacktrace: ${error?.stack}`;

    if(lastErrorReport !== message) {
        const xhr = new XMLHttpRequest();
        const scripturl = `${process.env.REACT_APP_BACKEND_URL}/errors/report`;
        const log = { errorReport: message };
        xhr.open('POST', scripturl);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.send(JSON.stringify(log));
        lastErrorReport = message;
    }
    return true;
}
